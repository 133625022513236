import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?p=213

const HistorieServeru: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/historie_serveru">Historie serveru</Link></h1>
      </div>
      <h2>5 let DKTechu – aktualizovaná historie serveru</h2>
      <p><strong>Rok se s rokem sešel a v těchto dnech slavíme 5 let existence serveru GamePark L2, známého širší a především starší veřejnosti spíše jako DKTech. Pokusím se zde opět shrnout a přiblížit společně s roční aktualizací, čím vším tento nejstarší server v České republice prošel, at již to byly události pozitivní nebo negativní.</strong></p>
      <p>Nejstarší počátky serveru jsou zahaleny mýty a legendami, přičemž některá fakta již asi pořádně zjistit nejdou. V roli kněžny Libuše se zde objevuje zakladatel serveru Odin, který někdy na přelomu října/listopadu 2004 pravil: “Budiž Lineage II server”. Před L2 sloužil server pro provoz MU Online. První písemná zmínka o L2 nalezená na Internetu pochází z 12.11.2004. Odin v té době provozoval v severních Čechách hernu s názvem DKTech, jejíž jméno převzal i server. Dohady je zahalen tehdejší typ serveru i raty. Proslýchá se, že úplně první jádro serveru bylo l2j, každopádně na začátku roku 2005 to již zcela určitě byla C1 beta. Raty byly nastavené kolem 4x, ale údajně mírně kolísaly.</p>
      <p>Na přelomu let 2004/2005 se vynořuje další legendární postava spjatá s DKTechem – Rodier. Jakožto známý Odina pracuje na serveru jako GM a později se stává adminem. První event pořádá Rodier v únoru v Hunters Village. Kromě Rodiera byli prvními GM Natalya a posléze Ave a Gotex. Od února 2005 již mohu podávat zprávy o serveru podložené vlastními zkušenostmi. Zavádí se “PvP liga”, do které vstupují i PK, protože jim je smazána komplet karma a PK body.</p>
      <p>Na jaře 2005 hraje na serveru přes 300 hráčů, ale server stejně jako v dobách nadcházejících sužují technické problémy. Jedním z nich byly neustálé pády NPC. Akcí, která se možná na jiném serveru snad nestala, byl běh DKTechu 48 hodin komplet bez NPC (celý server se bavil v aréně). Bohužel do běhu hry také začali větší či menší měrou zasahovat GM a admini.</p>
      <p>Přichází extender na C2 – a to 12. května 2005. Během července provedl Rodier úpravu safe enchantu, která vyústila 7. července 2005 v třídenní rollback. Po rollbacku zachvátila server vlna PK prováděná rozzuřenými hráči.</p>
      <p>Na přelomu června/července dosáhl 1. hráč na serveru, Dah4k, levelu 75 a byl odměněn Evilkou +20, slíbenou adminem za první dosažení levelu 75. V průběhu léta se správcem webu a hardwaru stává greenghost. C2 jsme si příliš neužili a již 26. července, po třech dnech nočního testování, přichází extender na C3. Na serveru hrálo kolem 600 postav. Další úpravou bylo zavedení nondropu, což vedlo k ohromnému nárůstu počtu PK (Marrana). Neexistence nových lokací (vadná geodata) se kompenzovala vytvořením takzvané lokace AL C2 – což byl prostor nad Antaras Lairem, kde byly volně naspawnovány moby z ToI.<br />
        Bohužel server se neustále potýkal s hardwarovými problémy, které vedly k odstavení serveru 12.10.2005 mimo provoz. Byl zakoupen nový hardware a provoz byl obnoven 1. listopadu 2005 (doprovázen 48 hodinovým rollbackem).</p>
      <p>Rodier se rozhodl odstávku kompenzovat hráčům přidělením adeny dle levelu jejich postav. To však vedlo k ohromným ziskům hráčů s většími počty postav a následné hyperinflaci. Multimilionáři si však příliš neužili, protože 26.11.2005 přichází dodnes nevysvětlený první wipe (údajně ho měl na svědomí “pan Natálek” poškozením databáze). Počet GM před wipem byl opravdu extrémní, zde můžete vidět výpis všech postav s builderem:&nbsp;<a href="http://dah4k.wz.cz/gms.html">http://dah4k.wz.cz/gms.html</a></p>
      <p>Po wipe začíná opět nanovo C1/C3 s raty 4x. K Vánocům nám server naděluje dárek v podobě svého pádu 24.12.2005. Opět jsou nutné úpravy hardwaru (nevhodné chlazení). Odin předává vlastnictví serveru Lungovi (Odin cestoval v té době domů do Vietnamu) a server se stěhuje na Slovensko. Startuje opět 27.1.2006, bohužel s rollbackem databáze až na 14.12.2005 (10 dní).</p>
      <p>S připojením do SR jsou problémy, některým hráčům velmi lagují linky. Na serveru se provádějí velké custom úpravy, at už se týká nastavní skillů (Natálkovi dýkaři s 2,6x silnějšími skilly) a debugují C3 lokace (Tower of Insolence a Fields of Whisper). Úpravy však někteří jedinci využívají ke svému prospěchu (nemrtví andělé atd.). Do praxe se zavádí “myš” alias prostředek nákupu materiálu u event managera a možnost smazání PK bodů (místo Sin Eatera).</p>
      <p>A pak přichází největší rána. Na počátku března padá náhle server i 6x denně až nakonec 4. března odchází do křemíkového nebe. Po mnoha peripetiích, při kterých server cestuje “půlkou Evropy” se nakonec vrací do Prahy a je nutná oprava základní desku a pamětí. Paměti se nakonec seženou jako dary hráčů. Server ožívá po 90 dnech 1. června 2006.</p>
      <p>Stávám se novým GM pod jménem Verthandi. V té době již Rodier pošilhával po C4, protože DKTech už dávno nebyl jediným serverem v ČR a začínal zaostávat za konkurencí.&nbsp;<strong>Začátkem srpna tak proběhl 24 hodinový testovací provoz C4 a mnozí z nás si dali svůj druhý wipe.</strong></p>
      <p>Současná éra serveru začíná 4.8.2006 v 16:45. Server je spuštěn na C4 PTS jádru, původně s rate 1x, později zvýšeným na 1,5x a posléze znovu na základě hlasování hráčů na současných 2x (na vše). Majitelem hardwaru je Lung, hlavním adminem Rodier.</p>
      <p>Většina GM skončila s wipem, zůstalo jen několik. Na serveru jsou problémy s některými C4 featurami, jako je manor, 7 signs, chyby geodat. V rámci možností jsou opravovány, i když některé zásahy nebyly veřejností přijaty zrovna kladně. Navíc se rozmáhá nešvar suportování si herní postavy GM charem, donate a jiné. Z těchto důvodů opouští server větší počet hráčů a počet online se ustaluje na cca 600.</p>
      <p>V průběhu září 2006 přechází DKTech pod křídla Gameparku, který zajištuje hosting. Tím končí jeho existence pod starým názvem. V prosinci 2006 čeká server několik převratných událostí, které značně ovlivní jeho další směřování. Vše začíná v listopadu “tajným enchantovacím eventem”, při kterém je safe enchant změněn na +5 a později na +8. Hráči se začínají bouřit (více zde:&nbsp;<a href="http://uberleet.ic.cz/pics/support_na_plechu.jpg">http://uberleet.ic.cz/pics/support_na_plechu.jpg</a>) proti Rodierovým neustálým zásahům do hry, ze hry defacto zmizeli GM, Rodier si zakládá herní postavu Honzoslava, se kterou ovlivňuje 7 signs vložením desítek tisíc seal stonů.</p>
      <p>Po sérii protestů (a možná i z jiných důvodů) se Rodier rozhoduje odstoupit z vedení serveru (a věnovat se čistě svému projektu – později Elmoreden). Lung předává 2.12.2006 hardware Rah. Server je v té době ve stavu “šrot” u Mamertuse (admin GP) doma, poté co byl “zavařen” při defragmentaci.</p>
      <p>Vzápětí je od Rodiera 21.12.2006 odkoupena databáze serveru. Na serveru v té době hraje kolem 750 online. Novým hlavním adminem se stává Dah4k (7framm, Noir), hlavním GM Verthandi. Po převzetí serveru v průběhu Vánoc podnikl někdo několik opakovaných útoků na server, které do vyřešení vedly k sérii pádů.</p>
      <p>V lednu vzalo nové vedení server pevně do svých rukou. Vznikly pevná pravidla pro hráče i pro GM, tvrdě byly omezeny práva GM a přistoupilo se k jejich výběru veřejným výběrovým řízením. Zároveň proběhlo rozsáhlé vyhledávání v databázi, jehož výsledkem bylo zabavení nasuportovaných a donatovaných itemů. Již 9. ledna byly smazány enchanty z akce “safe enchant” a do 24.1.2007 zmizely nasuportované itemy. Tím jsme se alespoň zčásti vypořádali s minulostí.</p>
      <p>V průběhu února narostl počet online na 900+, což bylo na hranici možností stávajícího hardware. Proto bylo 20.2. zavedeno systémové omezení multiklienta (což bohužel během pár dní většina hráčů obešla).<br />
        Pak přichází 3. březen, kdy vtipálek Bender několikrát shodí server exploitem a ještě se pokouší vydírat. Bezpečnostní díra je ve spolupráci s dalšími servery opravena do 24 hodin. O den později poprvé dosahuje server 1000 postav online. Dále se neustále opakují problémy s databází ovlivňující 7 signs, 12. března je kvůli nim server offline přes půl dne.</p>
      <p>Situace je dlouhodobě neudržitelná a proto v průběhu února a března probíhá sbírka hráčů na nový server. Ten je zakoupen a uveden do provozu 29.3.2007, zároveň je zrušeno omezení multiklienta. Po 3 dny pak probíhají zátěžové testy (masivní CS, dropy na zemi, summon RB). Osmého dubna je již 1200 online. V dubnu se opět opakují problémy se 7 signs a v půlce května s databází a OS, 18.5. server opět offline kvůli manoru (vše problémy s DB).</p>
      <p>Šestého června byly zavedeny notrade zóny v Giranu, v říjnu pak omezení používání maker na shout. Server od léta neměl závažnější problémy, kromě občasného flame vůči vedení při trestech za bugování na CS, hackování truhel, případně používání walkera. 19.10.2007 proběhlo promazání databáze, při kterém byly odstraněny neaktivní postavy (od 4.8.2007) a o 14 dní později rekonfigurace hardware i software serveru. 28. října 2007 server překonal hranici 1800 postav online (1100+ reálných hráčů).</p>
      <p>Koncem měsíce října došlo na řešení identity GM Shraina, kdy se zjistilo, že jde o Machaldu. Za podvod dostal několikaměsiční ban na herní postavu a zároveň mu byly předloženy podmínky pro výkon funkce Event GM.</p>
      <p>Na začátku prosince ukončil svou činnost GM Mykotaj. Sedmého prosince pak na serveru vypukla malá aféra poté, co po pádu serveru byla umožněna náhradní registrace na CS, což se některým klanům nelíbilo (proto se pro náhradní registraci ustanovila zvláštní pravidla). 16. prosince prošel GM Vallen zkušební lhůtou a stal se provozním GM.</p>
      <p>Těsně před Vánocemi nám Rodier nadělil “dárek” v podobě nového serveru DKTech. Na mnoha fórech se vedl flame ohledně práva na používání tohoto názvu. Z vedení “DKTechu” Rodier nakonec odstoupil a server se přejmenoval.</p>
      <p>Během Vánoc pak proběhly 2 eventy, jeden na Santovy čepice a druhý na Silvestrovské ohňostroje. Těsně před koncem roku odešel GM Almosthero. Počet online postav na serveru nadále rostl.</p>
      <p>Okolo 14. února se uskutečnil celoserverový Valentinský event. Následoval zákrok vůči hráčům s nevhodnými (vulgárními) nicky koncem února, kdy bylo několik desítek postav přejmenováno, případně zabanováno. Na konci února z důvodu nedostatku času ukončil svoji práci GM Bari.</p>
      <p>14.3. došlo k pokusu o opravu skillů Cancel a Elemental Protection, ale po hádkách hráčů o správnost nastavení nebyla část oprav dána do provozu. Koncem března proběhl velikonoční medailový event. Zároveň počet online postav dosáhl absolutního maxima, a to 2163 24. března večer. Od té doby pomalu klesal až do června, kde se zastavil na zhruba 1300. Vliv na to mělo spuštění nového oficiálního serveru Luna, jakožto i odpor některých skupin hráčů vůči vedení serveru. Koncem března došlo k definitivní úpravě truhel, aby je nebylo možné nijak rozeznávat.</p>
      <p>1. dubna se uskutečnil 24 hodinový GM den, kdy se server změnil v “pvp highrate” se vší dostupnou výbavou, skilly atd. Proběhly CS a různé eventy, GM účet dostali lidé, kteří se nějakým způsobem zasloužili o server. Ve stejný den se GM Vallen stal zástupcem hlavního GM. Součástí aprílu bylo i nové pravidlo o zákazu PvP a PK, které vzbudilo velký flame na fóru. Opravdu platná nová pravidla serveru vstoupila v platnost o 14 dní později.</p>
      <p>Mezi 21. a 24. dubnem pak proběhla odstávka a údržba serveru. Již předtím, od 19.4 se novými provozními GM stali Ebby a Tabitha. Celý květen proběhl pokojně, až na opětovný flame mezi hráči a vedením na konci měsíce, kdy vedení došla trpělivost s machaldou, který nejenže přišel o post Event GM, ale dostal totální ban na serveru.</p>
      <p>Na začátku prázdnin proběhl nejprve celoserverový dýňový event, následovaný poté otázkovým a dropovacím eventem o ingame ceny i o ceny od GameParku. Tento skončil v půlce září. Počátkem července bylo vybráno nové Event GM, a to Nadia. Během prázdnin začal počet online postav opět stoupat a pohyboval se okolo 1500.</p>
      <p>Počátkem září vedení oznámilo svůj plánovaný odchod. Ten se změnil ve skutečnost po dalším “GM dni”, proběhnuvším 1.11.2008. Ještě předtím proběhla opětovná údržba serveru. Po necelých dvou letech tak došlo k obměně ve vedení serveru, přičemž novým administrátorem se Vallen a Trash. Kurz nastavený post-rodierovským vedením se nadále zachoval.</p>
      <p>V průbehu vánoc se několikrát objevily podvodné phishingové emaily, zasílány některými hráči, které požadovali informace o účtech hráčů. Počátkem roku do týmu přibyl nový GM ve zkušební době, Teaven, který přišel ze serveru OnlineGamers (který prožíval existenční problémy).</p>
      <p>Také se server začal potýkat s několika desítkami pádů, které z ještě doteď ne úplně známých důvodů vždy odpojily server od sítě a k dalšímu fungování byl vyžadován “tvrdý” restart a oprava databáze (<a href="http://la2.gamepark.cz/?p=172">http://la2.gamepark.cz/?p=172</a>). Mezi tím, koncem února, nahradil neaktivního admina Trashe nový administrátor, poker10. Podobně jako Teaven, i on působil v minulosti na serverech OG. Web i fórum lineage stránek byly změnené do nové, lepší podoby a začala se tak již definitivně psát éra nového vedení.</p>
      <p>Po problémech s pády server čekala další nepříjemnost, a to technická odstávka. Ta proběhla od 13.3 do 23.3 a došlo k celkové optimalizaci, ladění, přenastavení výkonu a dalšímu odstranění výkonnostních problémů.</p>
      <p>Konečně přišlo také pokojné období, bez problémů a pádů, a tak si hráči mohli naplno vychutnat valentinský event. Ani v dubnu však nebyl problémům konec. Gamepark pozdě oznámil přesun serverů na jiné místo v serverovně a tak musel být ke konci přerušen i Valentinský event. Server to stálo další 2 dny offline.</p>
      <p>V květnu nebyla nouze o překotné události. Měsíc začal obviňováním členů GP týmu Teavena a pokera10 ze strany OnlineGamers ve věci krádeže databáze. Tato obvinění se však nepotvrdila. Hráči objevili novou chybu v olympiádě, která umožnila shazovat server, proto byly zápasy dočasně zakázány, až do doby, kdy došlo díky Frammovi k opravě této chyby.</p>
      <p>Vedení serveru vyhlásilo 11.5.2009 konkurz na nové provozní GM, ze kterého byly později vybrány 3 nová GM. Bohužel zkušební dobou neprošlo jedno GM a tak do ostrého provozu “postoupili” pouze Angani a Apanachee. V té době se hnul ke předu i vývoj serveru, když JohnyCage (nový developer) uskutečnil prvé testování Hellboundu (19.-22.5). Při&nbsp; testování bylo zaznamenáno mnoho chyb a server se proto opět vrátil na C4.</p>
      <p>Další zásadní událostí byla sbírka na databázi. Rah jako vlastník této databáze se jí rozhodla prodat “serveru” a bylo nutné dát dohromady 10000 Kč. Současně se však nesbíraly peníze pouze na databázi, ale i na nové komponenty serveru, přičemž vysbíraná suma představovala 32000 Kč (z toho 10000Kč na databázi). Serveru se pořídily nové RAM paměti, nový disk a raid.</p>
      <p>Event GM před prázdninami realizovali několik eventů, z těch nejzajímavějších a atypických to byl především event Miss GP a Wallpaper event. Začátkem léta začal i event Summer Squash. Léto probíhalo pokojně, došlo k nasazení nové registrace a PINů (čísel, sloužících k unikátní identifikaci majitelů účtů) a koncem července také k dalšímu testu Hellboundu. Ani tohle testování neukázalo provozuschopnost Hellboundu a tak se server vrátil na C4.</p>
      <p>Od 1.9 proběhla velká změna (resp. doplnění) pravidel a přestaly se řešit krádeže (jako důsledek sdílení účtů). Proběhl i zajímavý adena art event. Koncem září se vzdal postu Event GM Nadia. 8.10 pak server oslavil 5 let své existence.</p>
      <p>Říjen proběhl v pokojném duchu, na jeho konci se uskutečnila další plánovaná technická odstávka serveru. Tentokrát trvala pouze 3 dny a server byl od 2.11 znovu online. 11.1 server přisel o další GM, tentokrát provozní (Apanachee), který musel svou činnost ukončit díky supportu. Hned na to vyhlásilo vedení další konkurz, jak na event, tak i na provozní GM.</p>
      <p>V polovine listopadu bylo vybráno celkem 7 nových GM (provozní Aegir, Haydar, Lume, Ophryssia, Rein a event GM Amuro a Ziana). Všech 7 prošlo zkušební lhůtou. Koncem listopadu proběhl již třetí test Hellboundu, bohužel ani tento nebyl úspěšný. Server se vrátil na C4.</p>
      <p>Na vánoce, jak již bývá zvykem proběhl jak vánoční, tak i silvestrovský event. Tentokrát k nim přibyl přejmenovací event, který umožnil hráčům za poplatek přejmenovat svou postavu. Také proběhla malá vánoční amnestie.</p>
      <p>Začátek nového roka byl bohatý na události. Tou největší byla asi první vlna eliminace l2controlu a ban 40 hráčů. Po této vlně následovalo období flame, obviňování ze supportu, což vyústilo v další tresty. Novým hlavním GM se 7.1 stala Angani, původní Verthandi zůstal jejím zástupcem. Týden poté z postu administrátora odstoupil Vallen a na serveru tak zůstal jediný admin.</p>
      <p><strong>Jaký je tedy server dnes?</strong></p>
      <p>Stále má vedení, které se snaží o stabilní provoz serveru s minimem zásahů a úprav narušujících hru (opravy vyjímaje), do budoucna usilující o přechod na Hellbound bez wipe. Zároveň však tvrdě trvá na dodržování daných pravidel. Ve špičce je pravidelně 1600 – 1800 online postav. Podle unikátních IP se jedná o přibližně 900 hráčů. Během dne se jich zde v průměru vystřídá přes 1500, za týden 2x tolik. Použití walkerů a jiných programů je a bude stále zakázáno. Místní komunita se drží, ač občas umí být nepříjemná, jak se ukázalo především v okamžicích problémů s jejími členy (bany). Hráči již dávno dobyli všechny hrady a pokořili všechny World Bosse. Osobní vzpomínky hráčů včetně screnshotu jsou zde:&nbsp;<a href="http://la2.gamepark.cz/stare_forum/viewtopic.php?t=6199&amp;postdays=0&amp;postorder=asc&amp;start=0">http://la2.gamepark.cz/stare_forum/viewtopic.php?t=6199&amp;postdays=0&amp;postorder=asc&amp;start=0</a></p>
      <p>Zajímá-li vás více informací, můžete nás navštívit na&nbsp;<a href="https://dktech.cz/forum/index.php">https://dktech.cz/forum/index.php</a>&nbsp;.</p>
      <div>
        <div>Be Sociable, Share!</div>
      </div>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default HistorieServeru

export { Head } from '../components/defaultHtmlHead'